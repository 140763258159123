.navigation_bar {
    padding-top: 35px;
    height: calc(100vh - 160px);
    overflow: hidden scroll;
}

.exam_timer {
    font-size: 40px;
    font-weight: 700;
}

.questions_nav {
    display: flex;
    flex-wrap: wrap;
}

.questions_nav .question_el {
    background: #F0F0F0;
    border-radius: 8px;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    width: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 8px;
    border: 2px solid #F0F0F0;
    margin-top: 8px;
    cursor: pointer;
}

.questions_nav .question_el.current {
    border: 2px solid #296D9F;
}

.questions_nav .question_el.answered {
    background: #296D9F;
    border: 2px solid #296D9F;
    color: #fff;
}

.questions_nav .question_el.answered.question_el.current {
    border: 2px solid #e4ef13;
}