.exam_question_container {
    background: #F5F5F5;
    padding-top: 35px;
    height: calc(100vh - 160px);
    overflow: hidden scroll;
}

.answer {
    padding: 18px 24px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-top: 8px;
    width: 100%;
    cursor: pointer;
    border: 2px solid #fff;
}

.block {
    margin-top: 24px;
    width: 100%;
}

.answer.selected {
    border: 2px solid #296D9F;
}

.form-check-input {
    margin-top: .10em;
}

.reset_answer {
    text-decoration-line: underline;
    color: #296D9F;
    cursor: pointer;
}
.textarea_text_question{
    min-height: 150px;
    width: 100%;
    margin-top: 16px;
    background: #FFFFFF;
    border: 2px solid #296D9F;
    box-sizing: border-box;
    border-radius: 8px;
}
.textarea_text_question:focus, .textarea_text_question:hover{
    border: 2px solid #296D9F;
}

.text_answer_limit{
    display: flex;
    justify-content: flex-end;
}
textarea:focus-visible{
    outline: none !important;
}
.over_limit_text_answer, .over_limit_text_answer:focus, .over_limit_text_answer:hover, .over_limit_text_answer:focus-within, .over_limit_text_answer:focus-visible{
    border: 2px solid #C63838 !important;
    outline: none;
}
.textarea_bottom_label{
    position: absolute;
    left: 0;
    padding: 5px 10px;
    bottom: -28px;
    background: #eee;
    color: #296D9F;
    border-radius: 0 0 20px 20px;
}
.textarea_box{
    position: relative;
    width: 100%;
}


.lds-ring {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #296D9F;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #296D9F transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.disable_saved{
  opacity: 0.7;
}