.pw_label span {
    display: block;
    font-size: 12px;
    color: var(--bs-primary);
}

.pw_label img {
    width: 80px;
    margin-left: 30px;
}

header {
    padding: 16px 16px;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100%);
    z-index: 100;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.language-select-root img {
    max-width: 35px;
    margin-right: 8px;
    cursor: pointer;
    padding-bottom: 4px;
}

.language-select-root img.selected {
    border-bottom: 3px solid var(--bs-primary);
}