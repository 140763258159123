.exam_pagination_control {
    width: 100%;
    padding: 18px 18px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
}

.exam_pagination_control button:disabled {
    border-color: #F0F0F0;
    color: #F0F0F0;
    opacity: 1;
    cursor:not-allowed
}

.calculator_container{
    position: fixed;
    top: 100px;
    left:100px;
    cursor:move
}