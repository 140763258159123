.header_pw_by img {
    max-width: 150px;
}

.examBox {
    padding: 12px 16px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

#userHome{
    padding: 0 ;
}