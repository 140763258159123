/*MAIN STYLE*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,700&display=swap');

:root {
  --bs-primary: #296D9F;
  --bs-warning: #DF580C;
}

.text-warning {
  color: #DF580C !important;
}

.text-tf {
  color: var(--bs-primary);
}

body {
  font-family: 'Roboto' !important;
  font-style: normal;
}

.App {
  background: linear-gradient(180deg, #8ED2F8 0%, #6CAADB 100%);
  min-height: 100vh;
}

#mainContent {
  background: #fff;
  min-height: calc(100vh);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding: 75px 0 0 0;
  position: relative;
}

p {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: var(--bs-secondary);
}

a:hover {
  text-decoration: underline;
  color: var(--bs-secondary);
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #272727af;
  z-index: 1000;
}

.loader::before {
  content: '';
  background-image: url(./assets/icons/loader.gif);
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 150px;
  max-height: 150px;
}

/*MAIN CONTENT*/

header {
  padding: 16px 16px;
  position: fixed;
  top: 0;
  width: calc(100% - 290px);
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  z-index: 100;
  background: #fff;
}


body #mainContent header, body #mainContent footer {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/*Bottom Modal*/
.modal-content {
  padding: 26px;
  max-height: 90vh;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content.bottom_modal {
  opacity: 0;
  transform: translateY(100%);
  transition: all 500ms ease-in-out;
}

.ReactModal__Content--after-open.bottom_modal {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Content--after-close.bottom_modal {
  opacity: 0;
  transform: translateY(100%);
}

/*BUTTONS*/
button {
  padding: 12px 24px;
  border-radius: 8px;
  border: 2px solid #296D9F;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: initial;
  cursor: pointer;
  background: #296D9F;
  transition: 0.2s;
  display: block;
  width: fit-content;
}

button:hover {
  border: 2px solid #4F95CC;
}

button.white {
  border: none;
  background: #fff;
  color: #296D9F;
  border: 2px solid #296D9F;
}


/*INPUTS*/
.form-check-input:not([role=switch], [type=radio]) {
  width: 20px;
  height: 20px;
  border: 2px solid var(--bs-primary);
  padding: 6px !important;
  background: #fff;
}

.form-check-input:checked[type=checkbox]:not([role=switch]) {
  background: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjAuNzA3MTA3IiB5MT0iMy42OTI4IiB4Mj0iNC4wMDcxMSIgeTI9IjYuOTkyOCIgc3Ryb2tlPSIjMEIyMjQxIiBzdHJva2Utd2lkdGg9IjIiLz4KPGxpbmUgeDE9IjExLjcwNzEiIHkxPSIwLjcwNzEwNyIgeDI9IjQuMDA3MTEiIHkyPSI4LjQwNzExIiBzdHJva2U9IiMwQjIyNDEiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
}

.form-check-input:checked {
  border-color: var(--bs-primary) !important;
}

label {
  color: var(--bs-secondary);
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

input:not([type=radio], [role=switch]), textarea {
  font-size: 18px;
  line-height: 21px;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.standardInput {
  border: 0;
  background: #f0f0f0;
  border-radius: 4px;
  outline: none;
}

.pointer {
  cursor: pointer;
}