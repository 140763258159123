
#examContent {
    padding: 0 16px;
    position: relative;
}

.saving_loader{
    position: absolute;
    width: 100%;
    height: calc(100% - 80px);
    top: 80px;
    left: 0;
    z-index: 1;
    background-color: #fafafa16;
    
}

.saving_tooltip{
    position: absolute;
    right: 15px;
    top: 15px;
    background: var(--bs-primary);
    padding: 8px 16px;
    color: #fff;
    border-radius: 8px;
}